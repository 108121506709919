// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/socialMedia.js"
);
import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import facebookSvg from "../images/facebook.svg";
import youtube from "../images/youtube.png";
import instagramSvg from "../images/instagram.svg";
import whatsapp from "../images/whastapp.png";

const socialMedia = [
  {
    icon: facebookSvg,
    name: "Facebook",
    link: "https://www.facebook.com/SalamKidsTV",
  },
  {
    icon: instagramSvg,
    name: "Instagram",
    link: "https://www.instagram.com/salamkidstv",
  },
  {
    icon: whatsapp,
    name: "Whatsapp",
    link: "https://whatsapp.com/channel/0029VaDYOIiF6sn4AFQIGn2G",
  },
  {
    icon: youtube,
    name: "Youtube",
    link: "https://www.youtube.com/@SalamKidsTV",
  },
];

export default socialMedia;
